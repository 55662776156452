import React from 'react';
import Menu from '../components/Menu';
import './HowToUse.css';

function HowToUse() {
  return (
    <div className="how-to-use-container">
      <Menu />
      <div className="how-to-use-content">
        <div className="how-to-use-main-content">
          <h1 className="how-to-use-title">このシステムの使い方</h1>
          <section>
            <p>このサービスでは、中小企業診断士二次試験の論文添削を、お好きな年度・お好きな事例から、最小１事例単位でご依頼いただけます。</p>
            <h2>添削完了までの手順</h2>
            <b><p>詳細は、<a href="https://comachilab.jp/shindanshi/howtouse/" target="_blank" rel="noopener noreferrer">マニュアルページ</a>をご参照ください。</p></b>
            <ol>
              <li>
                <h3>添削サービスを選択する</h3>
                メニューの「ホーム」「販売中の添削サービス」ページに移動し、ご希望のプランを選択して決済を進めてください。
                <p className="note">※決済処理は外部の収納代行サービス「Stripe」（SSL/TLS技術により暗号化し、不正アクセスから保護）の決済システムにて完結いただけます。</p>
              </li>
              <li>
                <h3>添削して欲しい事例を公式サイトからダウンロードする</h3>
                中小企業診断士二次試験のサイトに過去問が掲載されています。以下の公式サイトから過去問をPDFファイルでダウンロードできます。
                <p><a href="https://www.j-smeca.jp/contents/010_c_/shikenmondai.html" target="_blank" rel="noopener noreferrer">問題用紙のダウンロードはこちら</a></p>
                <p className="note">※ページ内の「第２次試験問題」から対象のファイルを選択してダウンロードしてください。</p>
              </li>
              <li>
                <h3>回答用紙をダウンロードする</h3>
                以下のページから各事例に対応する回答用紙をダウンロードしてください。
                <p><a href="https://www.j-smeca.jp/contents/010_c_/shikenmondai.html" target="_blank" rel="noopener noreferrer">回答用紙のダウンロードはこちら</a></p>
                <p className="note">※ページ内の「第２次試験問題」から対象のファイルを選択してダウンロードしてください。</p>
              </li>
              <li>
                <h3>回答用紙に回答を記入する</h3>
                回答用紙を印刷して、論文を手書きで記入してください。
                <p className="note">※本論述試験が筆記試験であることを踏まえ、本番試験に近しい形で添削をしております。印刷および手書きでの記入をお願いします。</p>
              </li>
              <li>
                <h3>回答を記入した回答用紙ファイルをアップロードする</h3>
                記入済みの回答用紙をスキャンして、画面左のメニューの「ファイル送付」をクリックし、対象の事例を選択してファイルをアップロードしてください。必ずコメント欄に「令和元年事例１」「令和２年事例３」「令和３年事例２」など、対象の事例の年度と事例名を記入し、アップロードボタンをクリックしてください。担当者がアップロードされた回答用紙を添削します。
              </li>
              <li>
                <h3>添削済みファイルを確認する</h3>
                通常、3営業日以内に添削済みの回答用紙をアップロードします。画面左のメニューの「添削履歴」をクリックし、「添削者からの回答」欄から添削済みの回答ファイルをダウンロードしてご確認ください。
              </li>
            </ol>
          </section>
        </div>
      </div>
    </div>
  );
}

export default HowToUse;