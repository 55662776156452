import React, { useState } from 'react';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import './Login.css';
import { useNavigate, Link } from 'react-router-dom';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    const auth = getAuth();

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        console.log('Logged in user:', userCredential.user);
        navigate('/dashboard');
      })
      .catch((error) => {
        console.error('Login error:', error.message);
        alert('Login failed: ' + error.message);
      });
  };

  return (
    <div className="login-container">
      <div className="login-form">
        <h1>ログイン</h1>
        <form onSubmit={handleSubmit}>
          <div>
            <label>Email:
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </label>
          </div>
          <div>
            <label>Password:
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </label>
          </div>
          <div>
            <button type="submit">ログイン</button>
          </div>
          <p className="forgot-password-link">
            <Link to="/forgot-password">パスワードを忘れた場合はこちら</Link>
          </p>
          <p className="signup-link">
            IDを持っていない方は<Link to="/signup">新規登録</Link>してください。
          </p>
        </form>
      </div>
      <div className="login-image">
        <img src="/login.jpg" alt="Login illustration" />
      </div>
    </div>
  );
}

export default Login;