import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const FileUploadHistory = () => {
  const [uploadHistory, setUploadHistory] = useState([]);
  const auth = getAuth();
  const firestore = getFirestore();

  useEffect(() => {
    const fetchUploadHistory = async () => {
      const user = auth.currentUser;
      if (user) {
        const uploadsCollectionRef = collection(firestore, `user_files/${user.uid}/uploads`);
        const querySnapshot = await getDocs(uploadsCollectionRef);
        const files = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          // uploadTime を適切なフォーマットに変換します。
          uploadTime: doc.data().uploadTime.toDate().toLocaleString(),
        }));
        setUploadHistory(files);
      }
    };

    fetchUploadHistory();
  }, [auth, firestore]);

  return (
    <div className="file-upload-history">
      <h2>アップロード履歴</h2>
      {uploadHistory.length > 0 ? (
        <ul>
          {uploadHistory.map((file) => (
            <li key={file.id}>
              <span>ファイル名: {file.filename}</span> - <span>アップロード日時: {file.uploadTime}</span>
            </li>
          ))}
        </ul>
      ) : (
        <p>アップロードされたファイルはありません。</p>
      )}
    </div>
  );
};

export default FileUploadHistory;
