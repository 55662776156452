import React from 'react';
import { NavLink } from 'react-router-dom';
import './Navbar.css'; // スタイルシートをインポート

function Navbar() {
  return (
    <nav className="navbar">
      <img src="/logo_tensakun.png" alt="ロゴ" className="navbar-logo" />
    </nav>
  );
}

export default Navbar;
