import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate, Link } from 'react-router-dom';
import { getAuth, verifyPasswordResetCode, confirmPasswordReset } from 'firebase/auth';
import './ResetPassword.css';

function ResetPassword() {
  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const oobCode = searchParams.get('oobCode');
    if (!oobCode) {
      setError('無効なリセットコードです。');
    }
  }, [searchParams]);

  const handleReset = (event) => {
    event.preventDefault();
    const auth = getAuth();
    const oobCode = searchParams.get('oobCode');

    if (oobCode) {
      verifyPasswordResetCode(auth, oobCode)
        .then(() => {
          return confirmPasswordReset(auth, oobCode, newPassword);
        })
        .then(() => {
          setMessage('パスワードがリセットされました。');
          setError('');
          navigate('/login'); // パスワードリセット後にログインページに遷移
        })
        .catch((error) => {
          console.error('Error resetting password:', error);
          setError('パスワードのリセットに失敗しました。もう一度お試しください。');
          setMessage('');
        });
    }
  };

  return (
    <div className="reset-password-container">
      <div className="reset-password-form">
        <h1>新しいパスワードを設定</h1>
        <form onSubmit={handleReset}>
          <div>
            <label>新しいパスワード:
              <input
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
            </label>
          </div>
          <div>
            <button type="submit">パスワードをリセット</button>
          </div>
        </form>
        {message && <p className="reset-password-message">{message}</p>}
        {error && <p className="reset-password-error">{error}</p>}
        <div className="back-to-login">
          <Link to="/login">ログインページに戻る</Link>
        </div>
        <div className="signup-link">
          <p className="info-text">IDを持っていない方は <Link to="/signup">新規登録</Link> してください。</p>
        </div>
      </div>
      <div className="reset-password-image">
        <img src="/login.jpg" alt="Reset password illustration" />
      </div>
    </div>
  );
}

export default ResetPassword;