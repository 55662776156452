import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <ul>
            <li><a href="https://comachilab.jp/shindanshi/commerce-terms/" target="_blank" rel="noopener noreferrer">特定商取引法への対応</a></li>
          </ul>
        </div>
        <div className="footer-section">
          <ul>
            <li><a href="https://comachilab.jp/shindanshi/privacy-policy/" target="_blank" rel="noopener noreferrer">プライバシーポリシー</a></li>
          </ul>
        </div>
        <div className="footer-section">
          <ul>
            <li><a href="https://comachilab.jp/shindanshi/" target="_blank" rel="noopener noreferrer">運営会社ブログ</a></li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} 株式会社comachi All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;