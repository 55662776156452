// src/components/Menu.js
import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth'; // ここで signOut をインポートする
import './Menu.css'; // CSSファイルをインポート

function Menu() {
  const navigate = useNavigate();

  const handleLogout = () => {
    const auth = getAuth();
    signOut(auth).then(() => {
      // ログアウトが成功した場合の処理
      navigate('/login'); // ログインページにリダイレクト
    }).catch((error) => {
      // ログアウトでエラーが発生した場合の処理
      console.error('Logout error:', error);
    });
  };

  return (
    <div className="menu">
      {/* 他のナビゲーションリンク */}
      <NavLink to="/dashboard" className={({ isActive }) => (isActive ? 'active' : undefined)}>ホーム</NavLink>
      <NavLink to="/file-upload" className={({ isActive }) => (isActive ? 'active' : undefined)}>ファイルを送付</NavLink>
      <NavLink to="/answer" className={({ isActive }) => (isActive ? 'active' : undefined)}>添削履歴</NavLink>
      <NavLink to="/profile" className={({ isActive }) => (isActive ? 'active' : undefined)}>プロフィール</NavLink>
      <NavLink to="/how-to-use" className={({ isActive }) => (isActive ? 'active' : undefined)}>利用方法</NavLink> {/* 新しく追加 */}
      {/* ログアウトボタン */}
      <button onClick={handleLogout}>ログアウト</button>
    </div>
  );
}

export default Menu;