import React, { useState, useEffect } from 'react';
import { storage, firestore } from '../firebaseConfig';
import { ref as storageRef, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import Menu from '../components/Menu';
import './Fileupload.css';

const MAX_FILE_SIZE = 20 * 1024 * 1024; // 20MB

function FileUpload() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [description, setDescription] = useState(''); // 初期値を空欄にする
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [user, setUser] = useState(null);
  const [purchaseStatus, setPurchaseStatus] = useState('');
  const [error, setError] = useState('');

  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        const userDocRef = doc(firestore, 'users', currentUser.uid);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          setPurchaseStatus(userDocSnap.data().purchaseStatus);
        }
      }
    });
    return unsubscribe;
  }, [auth]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.size > MAX_FILE_SIZE) {
      alert('ファイルサイズが上限の20MBを超えています。');
      event.target.value = null; // ファイル選択をクリア
      setSelectedFile(null);
    } else {
      setSelectedFile(file);
    }
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const sanitizeFilename = (filename) => {
    return filename.replace(/[^a-zA-Z0-9_\u3000-\u303F\u3040-\u309F\u30A0-\u30FF\uFF00-\uFFEF]+/g, '_');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!selectedFile || !description) {
      setError('すべての必須項目を入力してください。');
      return;
    }
    if (!user) {
      setError('ユーザーが認証されていません。');
      return;
    }
    setError('');
    const sanitizedFilename = sanitizeFilename(selectedFile.name);
    const fileRef = storageRef(storage, `user_files/${user.uid}/${sanitizedFilename}`);
    const uploadTask = uploadBytesResumable(fileRef, selectedFile);
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgress(progress);
      },
      (error) => {
        console.error("アップロード中にエラーが発生しました:", error);
      },
      async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        setUploadComplete(true);
        const fileDocRef = doc(firestore, `user_files/${user.uid}/uploads`, sanitizedFilename);
        await setDoc(fileDocRef, {
          filename: sanitizedFilename,
          url: downloadURL,
          description: description,
          uploadTime: new Date()
        }, { merge: true });
      }
    );
  };

  return (
    <div className="file-upload-page">
      <Menu />
      <div className="file-upload-content">
        <div className="file-upload-container">
          <h1 className="file-upload-title">ファイルアップロード</h1>
          <form onSubmit={handleSubmit}>
            <div className="description-container">
              <label>
                添削情報記入欄: <span className="required">必須</span>
                <input
                  type="text"
                  value={description}
                  onChange={handleDescriptionChange}
                  maxLength="50"
                  placeholder="添削ご希望の事例、年度を記入ください。（複数事例プランご利用の場合は複数ご記入ください）" // プレースホルダーとして表示
                  disabled={purchaseStatus !== 'purchased'}
                  required
                />
              </label>
            </div>
            <div className="file-input-container">
              <label>
                ファイル: <span className="required">必須</span>
                <input
                  type="file"
                  onChange={handleFileChange}
                  className={`file-input ${purchaseStatus !== 'purchased' ? 'disabled' : ''}`}
                  required
                  disabled={purchaseStatus !== 'purchased'}
                  accept=".pdf"
                />
              </label>
            </div>
            {error && <p className="error-message">{error}</p>}
            <button
              type="submit"
              className={`upload-button ${purchaseStatus !== 'purchased' ? 'disabled' : ''}`}
              disabled={purchaseStatus !== 'purchased'}
            >
              アップロード
            </button>
          </form>
          {purchaseStatus !== 'purchased' && (
            <p className="warning-message">
              商品が購入されていないか、あるいはすでに添削済みのためファイルのアップロードができません。
            </p>
          )}
          <p className="file-size-limit">※ファイルサイズ上限: 20MB</p>
          <p className="file-size-limit">※複数ファイルの場合、一つのPDFファイルにまとめてください。</p>
          {uploadProgress > 0 && (
            <div>
              <label>アップロード進行状況: {uploadProgress.toFixed(2)}%</label>
              <progress value={uploadProgress} max="100"></progress>
            </div>
          )}
          {uploadComplete && (
            <div>
              <p>アップロードが完了しました。3営業日以内に添削済みの回答を送付いたします。今しばらくお待ちください。</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default FileUpload;