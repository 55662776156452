import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Menu from '../components/Menu';
import './ProductDetails.css';

const ProductDetails = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const res = await axios.get(`http://localhost:3001/products/${id}`);
        setProduct(res.data);
      } catch (error) {
        console.error('商品の詳細を取得できませんでした:', error);
      }
    };
    fetchProductDetails();
  }, [id]);

  const handleBuyNow = async () => {
    try {
      const { data } = await axios.post('http://localhost:3001/create-checkout-session', { productId: id });
      window.location.href = data.sessionUrl; // 修正: data.urlではなくdata.sessionUrlを使用する
    } catch (error) {
      console.error('Error during payment process:', error);
    }
  };

  if (!product) {
    return <div>読み込み中...</div>;
  }

  return (
    <div className="layout">
      <Menu />
      <div className="product-details">
        <h1>{product.name}</h1>
        {product.image_url && <img src={product.image_url} alt={`Image of ${product.name}`} />}
        <p>価格: ¥{product.price}</p>
        <p>{product.description}</p>
        <button onClick={handleBuyNow}>今すぐ購入</button>
        <p><Link to="/dashboard">商品一覧に戻る</Link></p>
      </div>
    </div>
  );
};

export default ProductDetails;