import React, { useState, useEffect } from 'react';
import { getAuth, signOut } from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { firestore } from '../firebaseConfig';
import Menu from '../components/Menu';
import './Profile.css';
import Button from '../components/Button';

function Profile() {
  const [profile, setProfile] = useState({
    name: '',
    prefecture: '',
    occupation: '',
    examAttempts: '',
    email: '',
    purchaseStatus: '',
  });
  const [editingField, setEditingField] = useState(null);
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    if (auth.currentUser) {
      const fetchProfile = async () => {
        const profileRef = doc(firestore, 'users', auth.currentUser.uid);
        const profileSnap = await getDoc(profileRef);
        if (profileSnap.exists()) {
          setProfile(profileSnap.data());
        } else {
          setProfile(prevProfile => ({
            ...prevProfile,
            email: auth.currentUser.email,
          }));
        }
      };
      fetchProfile();
    }
  }, [auth]);

  const handleLogout = () => {
    signOut(auth).then(() => {
      navigate('/login');
    }).catch((error) => {
      console.error('Logout error:', error);
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile(prevProfile => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  const handleEditClick = (field) => {
    setEditingField(field);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await setDoc(doc(firestore, 'users', auth.currentUser.uid), profile);
      setEditingField(null);
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  return (
    <div className="profile-container">
      <Menu />
      <div className="profile-content">
        <h1 className="profile-title">プロフィール</h1>
        <table className="profile-table">
          <tbody>
            <tr>
              <th>項目</th>
              <th>内容</th>
              <th>編集</th>
            </tr>
            <tr>
              <td>名前</td>
              <td>
                {editingField === 'name' ? (
                  <input
                    type="text"
                    name="name"
                    value={profile.name}
                    onChange={handleChange}
                  />
                ) : (
                  <span>{profile.name}</span>
                )}
              </td>
              <td>
                {editingField !== 'name' && (
                  <button type="button" className="button-link" onClick={() => handleEditClick('name')}>
                    編集
                  </button>
                )}
              </td>
            </tr>
            <tr>
              <td>メールアドレス</td>
              <td>{profile.email}</td>
              <td></td>
            </tr>
            <tr>
              <td>都道府県</td>
              <td>
                {editingField === 'prefecture' ? (
                  <select
                    name="prefecture"
                    value={profile.prefecture}
                    onChange={handleChange}
                  >
                    <option value="">選択してください</option>
                    <option value="tokyo">東京</option>
                    <option value="osaka">大阪</option>
                    {/* 他の都道府県 */}
                  </select>
                ) : (
                  <span>{profile.prefecture}</span>
                )}
              </td>
              <td>
                {editingField !== 'prefecture' && (
                  <button type="button" className="button-link" onClick={() => handleEditClick('prefecture')}>
                    編集
                  </button>
                )}
              </td>
            </tr>
            <tr>
              <td>職業</td>
              <td>
                {editingField === 'occupation' ? (
                  <input
                    type="text"
                    name="occupation"
                    value={profile.occupation}
                    onChange={handleChange}
                  />
                ) : (
                  <span>{profile.occupation}</span>
                )}
              </td>
              <td>
                {editingField !== 'occupation' && (
                  <button type="button" className="button-link" onClick={() => handleEditClick('occupation')}>
                    編集
                  </button>
                )}
              </td>
            </tr>
            <tr>
              <td>受験回数</td>
              <td>
                {editingField === 'examAttempts' ? (
                  <input
                    type="number"
                    name="examAttempts"
                    value={profile.examAttempts}
                    onChange={handleChange}
                    min="0"
                  />
                ) : (
                  <span>{profile.examAttempts}</span>
                )}
              </td>
              <td>
                {editingField !== 'examAttempts' && (
                  <button type="button" className="button-link" onClick={() => handleEditClick('examAttempts')}>
                    編集
                  </button>
                )}
              </td>
            </tr>
            <tr>
              <td>購入状況</td>
              <td>
                {profile.purchaseStatus === 'unpurchased' ? '未購入' :
                  profile.purchaseStatus === 'purchased' ? '購入済み' :
                    profile.purchaseStatus === 'reviewed' ? '添削済み' : ''}
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
        {editingField && (
          <Button text="更新" type="submit" onClick={handleSubmit} />
        )}
        <button onClick={handleLogout} className="logout-button">
          ログアウト
        </button>
      </div>
    </div>
  );
}

export default Profile;
