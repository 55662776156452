import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { collection, getDocs } from 'firebase/firestore';
import { firestore } from '../firebaseConfig';
import { getStorage, ref as storageRef, getDownloadURL } from 'firebase/storage';
import Menu from '../components/Menu';
import './Answer.css';
import FileUploadHistory from '../components/FileUploadHistory';

function Dashboard() {
  const [adminAnswers, setAdminAnswers] = useState([]);
  const auth = getAuth();
  const storage = getStorage();

  useEffect(() => {
    const fetchAnswers = async () => {
      if (auth.currentUser) {
        const userUid = auth.currentUser.uid;
        const answersCollectionRef = collection(firestore, `user_files/${userUid}/answers`);
        const answersSnapshot = await getDocs(answersCollectionRef);
        const answers = answersSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Map over each answer to get the download URL for its file
        const answersWithUrls = await Promise.all(
          answers.map(async (answer) => {
            if (answer.downloadURL) {
              return answer;
            } else {
              const fileRef = storageRef(storage, answer.storageLocation);
              try {
                const downloadURL = await getDownloadURL(fileRef);
                return { ...answer, downloadURL };
              } catch (error) {
                console.error('Error getting download URL:', error);
                return answer;
              }
            }
          })
        );

        setAdminAnswers(answersWithUrls);
      }
    };

    fetchAnswers();
  }, [auth]);

  // 日付をフォーマットするヘルパー関数
  function formatDate(timestamp) {
    if (timestamp && timestamp.toDate) {
      const date = timestamp.toDate();
      return date.toLocaleDateString();
    }
    return 'Invalid Date';
  }

  return (
    <div className="answer-page">
      <div className="layout">
        <div className="menu">
          <Menu />
        </div>
        <div className="answer-content">
          <h1>添削履歴</h1>
          <FileUploadHistory />
          <div className="admin-answers">
            <h2>添削済み回答履歴</h2>
            {adminAnswers.length > 0 ? (
              <ul>
                {adminAnswers.map((answer, index) => (
                  <li key={answer.id}>
                    <span>
                      {answer.downloadURL ? (
                        <a href={answer.downloadURL} target="_blank" rel="noopener noreferrer">
                          {answer.filename}
                        </a>
                      ) : (
                        answer.filename
                      )}
                    </span>
                    <span>アップロード日時: {formatDate(answer.uploadTime)}</span>
                  </li>
                ))}
              </ul>
            ) : (
              <p>回答はまだありません。</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
