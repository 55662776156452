import React, { useState } from 'react';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import './ForgotPassword.css';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleReset = (event) => {
    event.preventDefault();
    const auth = getAuth();

    const actionCodeSettings = {
      url: window.location.hostname === 'localhost' ? 'http://localhost:3000/reset-password' : 'https://www.shindanshitensakun.com/reset-password',
      handleCodeInApp: true,
    };

    sendPasswordResetEmail(auth, email, actionCodeSettings)
      .then(() => {
        setMessage('入力されたメールアドレスにパスワードリセットメールを送信しました。メールが届かない場合にはアカウントが存在しないか、入力したメールアドレスが間違っていますので、ご確認ください。');
        setError('');
      })
      .catch((error) => {
        console.error('Error sending password reset email:', error);
        setError(`メール送信に失敗しました。もう一度お試しください。 エラーコード: ${error.code}`);
        setMessage('');
      });
  };

  return (
    <div className="forgot-password-container">
      <div className="forgot-password-form">
        <h1>パスワードリセット</h1>
        <form onSubmit={handleReset}>
          <div>
            <label>Email:
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </label>
          </div>
          <div>
            <button type="submit">リセットメールを送信</button>
          </div>
        </form>
        {message && <p className="forgot-password-message">{message}</p>}
        {error && <p className="forgot-password-error">{error}</p>}
        <div className="back-to-login">
          <a href="/login">ログインページに戻る</a>
        </div>
        <div className="signup-link">
          <p>IDを持っていない方は<a href="/signup">新規登録</a>してください。</p>
        </div>
      </div>
      <div className="forgot-password-image">
        <img src="/login.jpg" alt="Forgot password illustration" />
      </div>
    </div>
  );
}

export default ForgotPassword;