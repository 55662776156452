import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const ProductList = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      const res = await axios.get('http://localhost:3001/products');
      setProducts(res.data);
    };
    fetchProducts();
  }, []);

  return (
    <ul>
      {products.map(product => (
        <li key={product.id}>
          <h3>{product.name}</h3>
          {product.image_url && <img src={product.image_url} alt={`Image of ${product.name}`} width="300" />}
          <p>Price: {product.price}</p>
          <p>{product.description}</p>
          <Link to={`/order`}>注文する</Link>
        </li>
      ))}
    </ul>
  );
};

export default ProductList;