// SDKの必要な関数をインポートします
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore } from 'firebase/firestore';

// あなたの Firebase 設定
const firebaseConfig = {
  apiKey: "AIzaSyBwXUTxh9kpT5G7PMVIGoWgUirx9LD-HYc",
  authDomain: "shindanshiapp.firebaseapp.com",
  projectId: "shindanshiapp",
  storageBucket: "shindanshiapp.appspot.com",
  messagingSenderId: "910190700909",
  appId: "1:910190700909:web:7ca89c9ed8f7d51d9b1264",
  measurementId: "G-W623Y9CBLL"
};

// Firebase アプリを初期化
const app = initializeApp(firebaseConfig);

// Firebase Storage の参照を取得
const storage = getStorage(app);

// Firebase Firestore の参照を取得
const firestore = getFirestore(app);

// app, storage, firestore をエクスポートします
export { app, storage, firestore };