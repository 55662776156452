import React from 'react';
import Menu from '../components/Menu';
import './Dashboard.css';

function Dashboard() {
  const plans = [
    {
      name: '1事例添削プラン',
      price: '3,000円（税抜）',
      details: '1事例のみの添削サービスとなります。事例1〜4のいずれの年度からでも、選択できます。',
      link: 'https://buy.stripe.com/9AQ8zR77c6QM8006op'
    },
    {
      name: '3事例添削プラン',
      price: '8,800円（税抜）',
      details: '3事例セットでの添削サービスです。事例1〜4のいずれの年度からでも、選択できます。',
      link: 'https://buy.stripe.com/9AQeYffDIejefss3ce'
    },
    {
      name: '4事例添削プラン',
      price: '11,000円（税抜）',
      details: '4事例セットでの添削サービスです。複数年度から選択できます。',
      link: 'https://buy.stripe.com/eVa6rJ2QW0so4NObIL'
    }
  ];

  return (
    <div className="dashboard-container">
      <div className="menu">
        <Menu />
      </div>
      <div className="dashboard-content">
        <h1>販売中の添削サービス</h1>
        <table className="table">
          <thead>
            <tr>
              <th>プラン名</th>
              <th>価格</th>
              <th>詳細</th>
              <th>購入</th>
            </tr>
          </thead>
          <tbody>
            {plans.map((plan, index) => (
              <tr key={index}>
                <td>{plan.name}</td>
                <td>{plan.price}</td>
                <td>{plan.details}</td>
                <td>
                  <a href={plan.link} target="_blank" rel="noopener noreferrer" className="button-link">
                    申込
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Dashboard;