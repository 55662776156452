import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Fileupload from './pages/Fileupload';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import Answer from './pages/Answer';
import Profile from './pages/Profile';
import Signup from './pages/Signup';
import ProductDetails from './pages/ProductDetails';
import ProductList from './components/ProductList';
import HowToUse from './pages/HowToUse';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import './App.css';

// Firebase Authをインポートする
import { getAuth } from 'firebase/auth';
// react-firebase-hooksからuseAuthStateをインポートする
import { useAuthState } from 'react-firebase-hooks/auth';
import { app } from './firebaseConfig'; // Firebase アプリケーションをインポート

function App() {
  // Firebaseの認証オブジェクトを取得
  const auth = getAuth(app);
  // ユーザーのログイン状態を監視
  const [user, loading, error] = useAuthState(auth);

  // ログイン中にロード状態やエラーがある場合は、対応する表示を行う
  if (loading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <Router>
      <div className="app-container">
        <header>
          <Navbar />
        </header>
        <div className="content">
          <Routes>
            <Route path="/" element={!user ? <Login /> : <Navigate to="/dashboard" />} />
            <Route path="/login" element={!user ? <Login /> : <Navigate to="/dashboard" />} />
            <Route path="/signup" element={!user ? <Signup /> : <Navigate to="/dashboard" />} />
            <Route path="/forgot-password" element={<ForgotPassword />} /> {/* ここを修正 */}
            {user && (
              <>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/file-upload" element={<Fileupload />} />
                <Route path="/answer" element={<Answer />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/products" element={<ProductList />} />
                <Route path="/product/:id" element={<ProductDetails />} />
                <Route path="/how-to-use" element={<HowToUse />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset-password" element={<ResetPassword />} />
              </>
            )}
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;