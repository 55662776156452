import React, { useState } from 'react';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import './Signup.css'; // CSSファイルのインポート

function Signup() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault(); // ページの再読み込みを防ぐ
    const auth = getAuth();

    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        console.log('User created and signed in:', userCredential.user);
        navigate('/dashboard');
      })
      .catch((error) => {
        let errorMessage;
        switch (error.code) {
          case 'auth/email-already-in-use':
            errorMessage = 'このメールアドレスはすでに使用されています。';
            break;
          case 'auth/invalid-email':
            errorMessage = '無効なメールアドレスです。';
            break;
          case 'auth/operation-not-allowed':
            errorMessage = 'メールアドレス/パスワードによるログインが無効になっています。';
            break;
          case 'auth/weak-password':
            errorMessage = 'パスワードが弱すぎます。もっと強固なものを設定してください。';
            break;
          default:
            errorMessage = '登録に失敗しました。もう一度お試しください。';
        }
        alert(errorMessage);
        console.error('Signup error:', error);
      });
  };

  return (
    <div className="signup-container">
      <div className="signup-form">
        <h1>新規登録</h1>
        <form onSubmit={handleSubmit}>
          <div>
            <label>Email:
              <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
            </label>
          </div>
          <div>
            <label>Password:
              <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
            </label>
          </div>
          <div>
            <button type="submit">登録</button>
          </div>
        </form>
      </div>
      <div className="signup-image">
        <img src="/signup.jpg" alt="Signup illustration" />
      </div>
    </div>
  );
}

export default Signup;